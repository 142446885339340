var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('SpinnerArea',{attrs:{"state":_vm.spinner}},[_c('div',{staticClass:"container mb-4 md:mb-8 lg:mb-10 xl:mb-12"},[_c('FormTextBlock',{attrs:{"content":{
                text: ("There are currently <strong> " + _vm.numberOfApplicationsWithoutBakedSummary + " </strong> applications without a baked summary.<br />Use the button to bake summaries for a batch of applications."),
            }}}),_c('FormField',{staticClass:"number-of-entries-to-load",attrs:{"model":_vm.numberOfEntriesToLoadModel,"content":{
                label: 'Number of applications to process',
            },"align-field":"belowLabel"}},[_c('FormInputText',{attrs:{"model":_vm.numberOfEntriesToLoadModel}})],1),_c('AppButton',{attrs:{"label":"Get bakin'"},on:{"button-click":_vm.bakeApplicationSummaries}}),_c('FormTextBlock',{staticClass:"mt-4",attrs:{"content":{
                text: _vm.bakeApplicationSummariesResultMessage,
            }}}),_c('FormTextBlock',{staticClass:"mt-16",attrs:{"content":{
                text: "If you want to delete all baked summaries, use this button:",
            }}}),_c('AppButton',{attrs:{"label":"Delete all baked summaries","character":"cross"},on:{"button-click":function($event){_vm.showLayer = true}}}),_c('FormTextBlock',{staticClass:"mt-4",attrs:{"content":{
                text: _vm.deleteApplicationSummariesResultMessage,
            }}}),_c('AppLayer',{attrs:{"show":_vm.showLayer},scopedSlots:_vm._u([{key:"text",fn:function(){return [_c('AppContent',{attrs:{"use-large-font":true,"content":"Sure? All baked summaries will get deleted."}})]},proxy:true},{key:"action1",fn:function(){return [_c('AppButton',{attrs:{"label":"Delete them!"},on:{"button-click":function($event){return _vm.deleteApplicationSummaries()}}})]},proxy:true},{key:"action2",fn:function(){return [_c('AppButton',{attrs:{"label":"Cancel","character":"cross"},on:{"button-click":function($event){_vm.showLayer = false}}})]},proxy:true}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }