






































































import Vue from 'vue';
import moment from 'moment';
import { SpinnerState } from '@/enums/spinnerState';
import { MessageType } from '@/enums/messageType';
import { dialogueMessageService } from '@/services/dialogueMessageService';
import { TextField } from '@/types/forms/fields/textField';
import { toolsRepository } from '@/api';
import SpinnerArea from '@/components/molecules/spinnerArea/SpinnerArea.vue';
import FormField from '@/components/molecules/formField/FormField.vue';
import FormInputText from '@/components/atoms/formInputText/FormInputText.vue';
import AppButton from '@/components/atoms/appButton/AppButton.vue';
import FormTextBlock from '@/components/molecules/formTextBlock/FormTextBlock.vue';
import AppContent from '@/components/atoms/appContent/AppContent.vue';
import AppLayer from '@/components/molecules/appLayer/AppLayer.vue';

export default Vue.extend({
    name: 'ToolBakeApplicationSummaries',
    components: {
        SpinnerArea,
        FormInputText,
        FormField,
        AppButton,
        FormTextBlock,
        AppContent,
        AppLayer,
    },
    data: () => {
        return {
            spinner: SpinnerState.off,
            gridSpinner: SpinnerState.off,
            numberOfEntriesToLoadModel: {
                value: '100',
            } as TextField,
            numberOfApplicationsWithoutBakedSummary: -1,
            bakeApplicationSummariesResultMessage: '',
            deleteApplicationSummariesResultMessage: '',
            showLayer: false,
        };
    },
    created() {
        if (this.$route.query.code === 'unauthorized') {
            dialogueMessageService.show('unauthorized', MessageType.error);
        }
    },
    async mounted() {
        this.spinner = SpinnerState.opaque;

        await this.updateApplicationsWithoutBakedSummary();

        this.spinner = SpinnerState.off;
    },
    methods: {
        async updateApplicationsWithoutBakedSummary(): Promise<void> {
            const fetchApplicationsResponse = await toolsRepository.bakeApplicationSummariesFetchApplications();
            this.numberOfApplicationsWithoutBakedSummary =
                fetchApplicationsResponse.total;
        },
        cleanMessages() {
            this.bakeApplicationSummariesResultMessage = '';
            this.deleteApplicationSummariesResultMessage = '';
        },
        async bakeApplicationSummaries(): Promise<void> {
            this.cleanMessages();
            this.spinner = SpinnerState.transparent;

            var limit = -1;
            try {
                var limitTemp = parseInt(
                    this.numberOfEntriesToLoadModel.value ?? '-1',
                );
                if (!isNaN(limitTemp)) {
                    limit = limitTemp;
                }
            } catch {
                // ignore
            }
            const processApplicationsResponse = await toolsRepository.bakeApplicationSummariesProcessApplications(
                limit,
            );
            const timeSpan = moment.duration(
                processApplicationsResponse.duration,
            );
            const readableTimeSpan = moment
                .utc(timeSpan.as('milliseconds'))
                .format('HH:mm:ss.SSS');
            this.bakeApplicationSummariesResultMessage = `Processed ${processApplicationsResponse.results} of ${processApplicationsResponse.total} applications without baked summary. It took ${readableTimeSpan}.`;
            await this.updateApplicationsWithoutBakedSummary();

            this.spinner = SpinnerState.off;
        },
        async deleteApplicationSummaries(): Promise<void> {
            this.showLayer = false;
            this.cleanMessages();
            this.spinner = SpinnerState.transparent;

            const deletedApplicationsResponse = await toolsRepository.bakeApplicationSummariesDeleteApplications();
            const timeSpan = moment.duration(
                deletedApplicationsResponse.duration,
            );
            const readableTimeSpan = moment
                .utc(timeSpan.as('milliseconds'))
                .format('HH:mm:ss.SSS');
            this.deleteApplicationSummariesResultMessage = `Baked summaries; Count before: ${deletedApplicationsResponse.total}, count after: ${deletedApplicationsResponse.results}. It took ${readableTimeSpan}.`;

            await this.updateApplicationsWithoutBakedSummary();

            this.spinner = SpinnerState.off;
        },
    },
});
